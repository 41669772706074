import { ITranslation } from '../../interfaces/translation.interface';
import { TranslationDto } from './translation.dto';

export class TranslationDE implements TranslationDto {
  countries: ITranslation = {
    GERMANY: 'Deutschland',
    SPAIN: 'Spanien',
    FRANCE: 'Frankreich',
    BULGARIA: 'Bulgarien',
    HUNGARY: 'Ungarn',
    UKRAINE: 'Ukraine',
    ROMANIA: 'Rumänien',
    THAILAND: 'Thailand',
    RUSSIA: 'Rußland',
    NIGERIA: 'Nigeria',
    POLAND: 'Polen',
    ITALY: 'Italien',
    COLOMBIA: 'Kolumbien',
    INDONESIA: 'Indonesien',
    ALBANIA: 'Albanien',
    PORTUGAL: 'Portugal',
    LITHUANIA: 'Litauen',
    BELARUS: 'Belarus',
    CHINA: 'China',
    VIETNAM: 'Vietnam',
    KENYA: 'Kenia',
    LATVIA: 'Litauen',
    SLOVAKIA: 'Slowakei',
    ARGENTINA: 'Argentinien',
    DOMINICAN_REPUBLIC: 'Dominikanische Republik',
    BRAZIL: 'Brasilien',
    AUSTRIA: 'Österreich',
    TÜRKIYE: 'Türkei',
    CZECHIA: 'Tschechien',
    VENEZUELA: 'Venezuela',
    MOROCCO: 'Marokko',
    ALGERIA: 'Algerien',
    GREECE: 'Griechenland',
    BOSNIA_AND_HERZEGOVINA: 'Bosnien und Herzegowina',
  };
  translation: ITranslation = {
    HOUSES: 'Häuser',
    OVERVIEW: 'Übersicht',
    CASH: 'Kasse',
    WEEKLY_BOOKINGS: 'Wochenübersicht',
    CONTACTS: 'Kontakte',
    SETTINGS: 'Einstellungen',
    PROFILE: 'Profil',
    SEARCH_CLIENT: 'Suche Kunden...',
    SEARCH: 'Suchen',
    ADD_CLIENT: 'Kunden hinzufügen',
    DELETE: 'Löschen',
    CLIENT_NO: 'Kunden Nr.',
    PHOTO: 'Bild',
    NAME: 'Name',
    DISPLAY_NAME: 'Künstlername',
    CATEGORY: 'Kategorie',
    RATING: 'Bewertung',
    STATUS: 'Status',
    EMAIL: 'E-Mail',
    CALL: 'Anrufen',
    CREATED_AT: 'Erstellt am',
    FIRST_NAME: 'Vorname',
    LAST_NAME: 'Nachname',
    ALIAS: 'Alias',
    ADDRESS: 'Adresse',
    DESCRIPTION: 'Beschreibung',
    SUBMIT: 'Abschicken',
    DESCRIPTION_PLACEHOLDER: 'Gib eine Beschreibung ein',
    INVALID_SOON: 'Demnächst ungültig',
    INVALID: 'Abgelaufen',
    MISSING_INFORMATION: 'Fehlende Daten',
    OK: 'OK',
    UPLOAD_AVATAR: 'Bild hier hereinziehen oder klicken',
    ALL: 'Alle',
    ACTIVE: 'Aktiv',
    INACTIVE: 'Inaktiv',
    WEB: 'Web',
    PHONE: 'Telefon',
    NOTES: 'Notizen',
    MOBILE: 'Mobil',
    PERSONAL_DOCUMENTS: 'Dokumente',
    EDIT: 'Bearbeiten',
    CREATE_BOOKING: 'Buchung erstellen',
    BOOKING_PERIOD: 'Buchungszeitraum',
    SELECT_HOUSE: 'Wähle ein Haus',
    DAILY: 'Täglich',
    WEEKLY: 'Wöchentlich',
    WEEKLY_PRICE: 'Wochenpreis',
    DAILY_PRICE: 'Tagespreise',
    HOUSE_INFO: 'Hausdaten',
    HOUSE: 'Haus',
    SELECT_ROOM: 'Wähle ein Zimmer',
    ROOM_INFO: 'Zimmer Details',
    TOTAL_PRICE: 'Gesamtpreis',
    IS_ROOM_FIXED: 'Ist Zimmer unveränderbar?',
    ALLOW_DOUBLE_BOOKING: 'Doppelbuchung erlauben?',
    MON: 'Mo.',
    TUE: 'Di.',
    WED: 'Mi.',
    THU: 'Do.',
    FRI: 'Fr.',
    SAT: 'Sa.',
    SUN: 'So.',
    CONFIRM_EDIT_DOCUMEMT: 'Bist du sicher, dass du das Dokument bearbeiten willst?',
    LANGUAGE: 'Sprache',
    DATE: 'Datum',
    ACTIONS: 'Aktionen',
    IS_SIGNED: 'Ist unterschrieben',
    TITLE: 'Titel',
    TYPE: 'Art',
    TYPES: 'Art',
    TYPES_SELECT: 'Wähle eine Art',
    CLEAR: 'Löschen',
    DOWNLOAD_PDF: 'PDF herunterladen',
    DATA: 'Daten',
    CONTRACTS: 'Verträge',
    HISTORY: 'Buchungen',
    INVOICES: 'Rechnungen',
    TRANSACTIONS: 'Zahlungen',
    ADD_CONTRACT: 'Vertrag hinzufügen',
    GERMAN: 'Deutsch',
    ENGLISH: 'Englisch',
    ROOM_NO: 'Zimmernr.',
    INVOICE_NO: 'Rechnungsnr.',
    TAX_RATE: 'UST Satz',
    VAT: 'UST',
    NET: 'Netto',
    TOTAL: 'Gesamt',
    GROSS: 'Brutto',
    PAID: 'Bezahlt',
    STORNO: 'Storno',
    OPEN_AMOUNT: 'Offener Betrag',
    CONFIRMED: 'Bestätigt',
    BOOKED_AT: 'Buchungsdatum',
    BOOKED: 'Gebucht',
    CANCELED: 'Abgesagt',
    DELETED: 'Gelöscht',
    UNKOWN: 'Unbekannt',
    RECEIVED_AT: 'Erhalten am',
    INVOICE_DATE: 'Rechnungsdatum',
    CONTRACT_TEMPLATES: 'Vertragsvorlagen',
    MESSAGE_TEMPLATES: 'Nachrichtenvorlagen',
    USERS: 'Benutzer',
    EVENT: 'Ereignis',
    CONTENT: 'Inhalt',
    ENTER_TEXT: 'Gib den Inahlt hier ein...',
    CITY: 'Stadt',
    TERMS: 'AGB',
    PRIVACY: 'Datenschutz',
    LOAN: 'Kredit',
    RENT: 'Miete',
    HINTS: 'Hinweise',
    ADS: 'Werbung',
    ADD_CONTRACT_TEMPLATE: 'Neue Vertragsvorlage anlegen',
    ADD_NEW_USER: 'Neuen Benutzer anlegen',
    ADD_MESSAGE_TEMPLATE: 'Neue Nachrichtenvorlage anlegen',
    USERNAME: 'Benutzername',
    AVATAR: 'Avatar',
    ROLES: 'Rollen',
    PASSWORD: 'Passwort',
    PLEASE_SELECT: 'Bitte auswählen',
    READ_ONLY: 'Nur lesen?',
    NUMBER_OF_TRANSACTIONS: 'Anzahl Zahlungen',
    SUM_OF_TRANSACTIONS: 'Summe der Zahlungen',
    USER: 'Benutzer',
    OR: 'oder',
    AND: 'und',
    PASTE_BOOKING: 'Buchung einfügen',
    CUT_BOOKING: 'Buchung ausschneiden',
    CREATE_PAYMENT: 'Zahlung erfassen',
    EDIT_BOOKING: 'Buchung bearbeiten',
    VIEW_CLIENT_DETAILS: 'Kundendaten ansehen',
    CONFIRM_BOOKING: 'Buchung bestätigen',
    CANCEL_BOOKING_BY: 'Buchung abgesagt durch',
    DELETE_BOOKING_BY_US: 'Stornierung der Buchung durch uns',
    ROOM_NAME: 'Zimmername',
    BOOKING: 'Buchung',
    LOGOUT: 'Abmelden',
    AMOUNT: 'Betrag',
    PAYMENT_DATE: 'Zahldatum',
    ADD_NOTES: 'Notiz hinzufügen?',
    PAYMENT: 'Zahlung',
    PRICE: 'Preis',
    FIXED_ROOM: 'Festes Zimmer?',
    AUTO_CREATE_NEXT_BOOKING: 'Automatische Verlängerung?',
    CONFIRM_CREATION_OF_BOOKING: 'Are you sure creating new booking on this house/room, period?',
    SELECT_CLIENT: 'Kunden auswählen',
    CLIENT: 'Kunde',
    PERIOD_END_DATE: 'Enddatum',
    NEW_BOOKING: 'Neue Buchung',
    NEW_BOOKING_SUCCESS: 'Die Buchung wurde erfolgreich erstellt.',
    ERROR_MSG: 'Es ist ein Fehler aufgetreten!',
    CONFIRM_DELETE_CLIENT: 'Sollen die Kunden gelöscht werden?',
    FRONT_SIDE: 'Vorderseite',
    BACK_SIDE: 'Rückseite',
    UPDATE_DOCUMENT: 'Dokument aktualisieren',
    REPLACE_DOCUMENT_TITLE: 'Dokument ersetzen',
    REPLACE_DOCUMENT: 'Soll das bestehende Dokument durch das neue ersetzt werden?',
    PRENAME: 'Vorname',
    SURNAME: 'Nachname',
    DATEOFBIRTH: 'Geburtsdatum',
    DATEOFISSUE: 'Datum der Ausstellung',
    DATEOFEXPIRY: 'Gültig bis',
    IDNUMBER: 'Ausweisnummer',
    ISSUEDBY: 'Ausgestellt von',
    PLACEOFBIRTH: 'Geburtsort',
    NATIONALITY: 'Nationalität',
    ISSUINGCOUNTRY: 'Ausstellungsland',
    ISSUINGCITY: 'Ort der Ausstellung',
    STREET: 'Straße',
    POSTCODE: 'PLZ',
    COUNTRY: 'Land',
    ISSUINGNUMBER: 'Ausstellungsnr.',
    VALIDFOR: 'Gültig in',
    BOOKING_CANCELED: 'Die Buchung wurde storniert.',
    CONFIRM_DELETE_BOOKING: 'Soll die Buchung gelöscht werden?',
    COPY_BOOKING_MSG: 'Die Buchung wurde kopiert',
    BOOKING_DELETED_MSG: 'Die Buchung wurde gelöscht',
    BOOKING_UPDATED_MSG: 'Die Buchung wurde erfolgreich aktualisiert.',
    WEEK: 'Woche',
    CONFIRM_BOOKING_SUCCESS: 'Die Buchung wurde bestätigt.',
    NEW_PAYMENT: 'Neu Zahlung erfassen',
    PAYMENT_CREATION_SUCCESS: 'Die Zahlung wurde erfolgreich erfasst.',
    DAY: 'Tag',
    START: 'Start',
    END: 'Ende',
    WEEKLY_PDF_WITH_ALL_ROOMS: 'PDF mit allen Zimmern',
    WEEKLY_PDF_WITHOUT_FREE_ROOMS: 'PDF ohne freie Zimmer',
    WEEKLY_PDF_DOWNLOAD_CONFIRM: 'Möchtest du das PDF herunterladen?',
    FREE: 'Frei',
    NOT_PAID: 'Unbezahlt',
    TOTAL_ROOMS: 'Gesamtanzahl Zimmer',
    FREE_ROOMS: 'Freie Zimmer',
    BOOKED_ROOMS: 'Gebuchte Zimmer',
    UPDATE_BOOKING_SUCCESS: 'Die Buchung wurde aktualisiert.',
    NOT_ASSIGNED_CLIENTS: 'Nicht zugeordnete Kunden',
    SHOW_COVER: 'Hausbild anzeigen',
    HIDE_COVER: 'Hausbild ausblenden',
    REMOVE_ROOM_FROM_BOOKING: 'Zimmer aus der Buchung löschen',
    NEW_CONTRACT_TEMPLATE: 'Neue Vertragsvorlage',
    CONTRACT_TEMPLATE: 'Vertragsvorlage',
    NEW_CONTRACT_TEMPLATE_SUCCESS: 'Neue Vertragsvorlage wurde erstellt.',
    UPDATE_CONTRACT_TEMPLATE_SUCCESS: 'Die Vertragsvorlage wurde aktualisiert.',
    DELETE_CONTRACT_TEMPLATE_SUCCESS: 'Die Vertragsvorlage wurde gelöscht.',
    CONFIRM_DELETE_CONTRACT_TEMPLATE: 'Soll diese Vertragsvorlage wirklich gelöscht werden?',
    NEW_MESSAGE_TEMPLATE: 'Neue Nachrichtenvorlage',
    MESSAGE_TEMPLATE: 'Nachrichtenvorlage',
    NEW_MESSAGE_TEMPLATE_SUCCESS: 'Neue Nachrichtenvorlage wurde gespeichert.',
    UPDATE_MESSAGE_TEMPLATE_SUCCESS: 'Nachrichtenvorlage wurde aktualisiert.',
    DELETE_MESSAGE_TEMPLATE_SUCCESS: 'Nachrichtenvorlage wurde gelöscht.',
    CONFIRM_DELETE_MESSAGE_TEMPLATE: 'Soll diese Nachrichtenvorlage wirklich gelöscht werden?',
    NEW_USER_SUCCESS: 'Neuer Benutzer wurde gespeichert.',
    UPDATE_USER_SUCCESS: 'Benutzer wurde aktualisiert.',
    DELETE_USER_SUCCESS: 'Benutzer wurde gelöscht.',
    CONFIRM_DELETE_USER: 'Soll dieser Benutzer wirklich gelöscht werden?',
    CREATE_NEW_CLIENT: 'Neuen Kunden erstellen',
    DELETE_CLIENT_SUCCESS: 'Kunden wurden gelöscht.',
    DELETE_CLIENTS: 'Kunden löschen',
    OTHER: 'Sonstiges',
    NEW_HOUSE: 'Neues Haus anlegen',

    MONDAY: 'Montag',
    TUESDAY: 'Dienstag',
    WEDNESDAY: 'Mitwoch',
    THURSDAY: 'Donnerstag',
    FRIDAY: 'Freitag',
    SATURDAY: 'Samstag',
    SUNDAY: 'Sonntag',
    PRICES: 'Preise',
    HOUSE_NAME: 'Hausname',
    UPLOAD_IMAGE: 'Bild hier hereinziehen oder klicken',
    ROOMS: 'Zimmer',
    INFO: 'Info',
    SQUARE_METERS: 'Quadratmeter',
    FLOOR: 'Etage',
    BOOKING_RATIO: 'Booking ratio',
    EDIT_HOUSE: 'Haus bearbeiten',

    NEW_ROOM: 'Neues Zimmer',
    ADD_NEW_ROOM: 'Neues Zimmer anlegen',
    WARNING: 'Warnung',
    EDIT_ROOM: 'Zimmer bearbeiten',
    ROOM_COLOR: 'Zimmerfarbe',
    INVOICE: 'Rechnung',
    START_DATE: 'Beginn',
    END_DATE: 'Ende',
    OPEN: 'Offen',
    ANALYSIS: 'Auswertung',
    OVERVIEW_PERIOD: 'Übersicht nach Zeitraum',
    CLIENTS: 'Kunden',
    BACK: 'Zurück',
    ADD_MORE: 'Hinzufügen',
    TURNOVER: 'Umsatz',
    BOOKINGS: 'Buchungen',
    PAST_TURNOVER: 'Bisheriger Umsatz',
    PAST_BOOKINGS: 'Bisherige Buchungen',
    PAST_OPEN: 'Bisher Offen',
    FUTURE_TURNOVER: 'Zukünftiger Umsatz',
    FUTURE_BOOKINGS: 'Zukünftige Buchungen',
    FUTURE_OPEN: 'Zukünftig Offen',
    NEW_CONTRACT: 'Neuer Vertrag',
    CONTRACT: 'Vertrag',
    SAVE: 'Speichern',
    GO_TO: 'Zurück zu',
    GPASS: 'Gesundheitspass',
    IDCARD: 'Ausweis',
    HPASS: 'Arbeitspass',
    NATIONWIDE: 'Bundesweit',
    THURINGA: 'Thüringen',
    SCHLESWIG_HOLSTEIN: 'Schleswig-Holstein',
    SAXONY_ANHALT: 'Sachsen-Anhalt',
    SAXONY: 'Sachsen',
    SAARLAND: 'Saarland',
    RHINELAND_PALATINATE: 'Rheinland-Pfalz',
    NORTH_RHINE_WESTPHALIA: 'Nordrhein-Westfalen',
    LOWER_SAXONY: 'Niedersachsen',
    MECKLENBURG_WESTERN_POMERANIA: 'Mecklenburg-Vorpommern',
    HESSE: 'Hessen',
    HAMBURG: 'Hamburg',
    BREMEN: 'Bremen',
    BRANDENBURG: 'Brandenburg',
    BERLIN: 'Berlin',
    BAVARIA: 'Bayern',
    BADEN_WUERTTEMBERG: 'Baden Württemberg',
    UNRELIABLE: 'Unzuverlässig',
    RELIABLE: 'Zuverlässig',
    ALIAS_ORG_ID: 'Verwaltungsnummer', //issuingNumber
    ALIAS_ID: 'HPass Nummer', // idNumber
    NEW_DOCUMENT: 'Neus Dokument',
    NEW: 'Neues',
    NEW_DOCUMENT_CREATED_SUCCESSFULLY: 'Dokument wurde erfolgreich erstellt.',
    CUT_ALL_BOOKINGS: 'Alle Buchungungen ausschneiden',
    PASTE_ALL_BOOKINGS: 'Alle Buchungungen einfügen',
    AT: 'am',
    CLIENT_TYPE: 'Kundenart',
    DOMINA: 'Domina',
    TS: 'TS',
    WOMAN: 'Frau',
    EMPTY_STRING: '',
    DELETE_IMAGE: 'Bild löschen',
    DOCUMENT_IMAGE_COULD_NOT_BE_DELETED: 'Das Bild konnte für das Dokument nicht gelöscht werden!',
    CANCEL: 'Abbrechen',
    DELETE_DOCUMENT_IMAGE_CONFIRM: 'Bild löschen',
    DELETE_IMAGE_FROM_DOCUMENT: 'Bild von diesem Dokument löschen',
    UNCONFIRM_BOOKING: 'Bestätigung zurücknehmen',
    UNCONFIRM_BOOKING_SUCCESS: 'Die Buchungsbestätigung wurde erfolgreich zurückgenommen.',
    SINCE: 'Seit dem',
    HOUSENO: 'Haus Nr.',
    ARRIVED_IN_HOUSE: 'Ist angekommen',
    CLIENT_ARRIVED_BOOKING_SUCCESS: 'Der Status ist auf angekommen geändert.',
    SIGN_IN: 'Anmelden',
    PASSWORD_FORGOTTEN: 'Passwort vergessen?',
    REMEMBER_ME: 'Benutzernamen speichern',
    SIGN_IN_ACCOUNT: 'Im System anmelden',
    EMAIL_OR_USER: 'E-Mail oder Benutzer',
    PAST_DAYS: 'Bisherige Tage',
    FUTURE_DAYS: 'Zukünftige Tage',
    DAYS: 'Tage',
    CREATE_OTHER_PAYMENT: 'Sonstige Zahlungen',
    PAYMENT_PURPOSE: 'Zahlungsgrund',
    PAYMENT_CATEGORY: 'Kategorie der Zahlung',
    FEE: 'Gebühr',
    DEPOSIT: 'Kaution',
    PAYMENT_CREATION_FAILED: 'Die Zahlung konnte nicht erfasst werden',
    PAYMNET_DATE_ONLY_WHEN_RECEIVED: 'Das Datum nur bei Erhalt der Zahlung auswählen.',
    UNDELETE: 'Wiederherstellen',
    CONFIRM_UNDELETE_CLIENT: 'Soll der Kunde wiederhergestellt werden?',
    CREATED: 'Erstellt',
    REFUND: 'Erstattet',
    OPEN_AMOUNT_OTHER: 'Offen Sonstige',
    PROVE: 'Belege',
    ADD_PROVE: 'Beleg hinzufügen',
    PROVE_EDIT: 'Belege bearbeiten',
    DOCUMENT: 'Dokument',
    PROVE_IMAGE_COULD_NOT_BE_DELETED: 'Der Beleg konnte nicht gelöscht werden!',
    ROTATE_IMAGE: 'Bild drehen',
    PROVE_IMAGE_COULD_NOT_BE_ROTATED: 'Der Beleg konnte nicht gedreht werden!',
    MESSAGES: 'Nachrichten',
    CANCELED_DAYS: 'Abgesagte Tage',
    CANCELED_BOOKINGS: 'Abgesagte Buchungen',
    CHECKED: 'Auswahl',
    ROOM: 'Zimmer',
    UPDATE_PAYMENT: 'Zahlung bearbeiten',
    CREATE_BOOKING_FOR_EACH_DAY: 'Buchung täglich erstellen',
    IS_NEW_PAYMENT: 'Als neue Zahlung erfassen',
    FEES: 'Gebühren',
    HOUSES_BOOKINGS: 'Häuser Buchungen',
    ROOMS_BOOKINGS: 'Zimmer Buchungen',
    HOUSES_TURNOVER: 'Häuser Zahlungen',
    ROOMS_TURNOVER: 'Zimmer Zahlungen',
    UNPAID: 'Offene Posten',
    PAID_FEE: 'Bezahlte Gebühr',
    OPEN_AMOUNT_FEE: 'Offene Gebühr',
    CLIENT_NAME: 'Kunde',
    PAID_DEPOSIT: 'Bezahlte Kaution',
    OPEN_AMOUNT_DEPOSIT: 'Offene Kaution',
    COMMENT: 'Bemerkung',
    REFUND_DEPOSIT: 'Kaution zurückzahlen',
    TEMPLATES: 'Vorlagen',
    FINANCIAL: 'Finanzen',
    REPORTS: 'Berichte',
    ROOM_PLAN: 'Zimmerbelegung',
    NO_CONTRACT: 'Kein Vertrag',
    CONTRACT_SIGNED: 'Vertrag unterschrieben',
    CONTRACT_NOT_SIGNED: 'Vertrag nicht unterschrieben',
  };
}
