<nz-descriptions [nzTitle]="translation['HOUSE_INFO']" *ngIf="house !== undefined">
  <nz-descriptions-item [nzTitle]="translation['HOUSE']" [nzSpan]="3">{{house?.name}}</nz-descriptions-item>
  <nz-descriptions-item [nzTitle]="translation['HOUSENO']" [nzSpan]="3">{{house?.houseNo}}</nz-descriptions-item>
  <nz-descriptions-item [nzTitle]="translation['ADDRESS']" [nzSpan]="3">
    {{ house?.address?.country }}, {{ house?.address?.city }}, {{ house?.address?.street }} {{
    house?.address?.housenumber }}, {{ house?.address?.postcode }}
  </nz-descriptions-item>
  <nz-descriptions-item [nzTitle]="translation['WEEKLY_PRICE']" [nzSpan]="3">
    €{{house?.prices?.weekly}}
  </nz-descriptions-item>
</nz-descriptions>
<nz-form-item *ngIf="house !== undefined">
  <nz-form-label>{{translation['DAILY']}}</nz-form-label>
  <nz-form-control>
    <nz-tag>
      {{translation['MON']}}: €{{ house.prices?.daily?.monday }}
    </nz-tag>
    <nz-tag>
      {{translation['TUE']}}: €{{ house.prices?.daily?.tuesday }}
    </nz-tag>
    <nz-tag>
      {{translation['WED']}}: €{{ house.prices?.daily?.wednesday }}
    </nz-tag>
    <nz-tag>
      {{translation['THU']}}: €{{ house.prices?.daily?.thursday }}
    </nz-tag>
    <nz-tag>
      {{translation['FRI']}}: €{{ house.prices?.daily?.friday }}
    </nz-tag>
    <nz-tag nzColor="red">
      {{translation['SAT']}}: €{{ house.prices?.daily?.saturday }}
    </nz-tag>
    <nz-tag nzColor="red">
      {{translation['SUN']}}: €{{ house.prices?.daily?.sunday }}
    </nz-tag>
  </nz-form-control>
</nz-form-item>
<nz-descriptions>
</nz-descriptions>
<nz-descriptions [nzTitle]="translation['ROOM_INFO']" *ngIf="room !== undefined">
  <nz-descriptions-item [nzTitle]="translation['ROOM_NAME']">
    {{ room?.name }}
  </nz-descriptions-item>
  <nz-descriptions-item [nzTitle]="translation['ROOM_NO']">
    {{ room?.roomNumber }}
  </nz-descriptions-item>
  <nz-descriptions-item [nzTitle]="translation['WEEKLY_PRICE']" [nzSpan]="3">
    €{{ room?.prices?.weekly }}
  </nz-descriptions-item>
</nz-descriptions>
<nz-form-item *ngIf="room !== undefined">
  <nz-form-label>{{translation['DAILY_PRICE']}}</nz-form-label>
  <nz-form-control>
    <nz-tag>
      {{translation['MON']}}: €{{ room.prices?.daily?.monday }}
    </nz-tag>
    <nz-tag>
      {{translation['TUE']}}: €{{ room.prices?.daily?.tuesday }}
    </nz-tag>
    <nz-tag>
      {{translation['WED']}}: €{{ room.prices?.daily?.wednesday }}
    </nz-tag>
    <nz-tag>
      {{translation['THU']}}: €{{ room.prices?.daily?.thursday }}
    </nz-tag>
    <nz-tag>
      {{translation['FRI']}}: €{{ room.prices?.daily?.friday }}
    </nz-tag>
    <nz-tag nzColor="red">
      {{translation['SAT']}}: €{{ room.prices?.daily?.saturday }}
    </nz-tag>
    <nz-tag nzColor="red">
      {{translation['SUN']}}: €{{ room.prices?.daily?.sunday }}
    </nz-tag>
  </nz-form-control>
</nz-form-item>