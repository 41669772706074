import { Injectable } from '@angular/core';
import { AppUserDto } from '../../dto/app.user.dto';
import { BookingDto } from '../../dto/booking.dto';
import { ClientDto } from '../../dto/client.dto';
import { TranslationService } from '../translation/translation.service';

export interface IColor {}
@Injectable({
  providedIn: 'root',
})
export class CoreService {
  public static colors = {
    bookedRoom: '',
    freeRoom: '',
    confirmedRoom: '',
    arrivedInRoom: '',
    clientTypeTS: '#db2c72',
    clientTypeDefault: 'lightgreen',
    clientTypeDomina: 'darkgrey',
    clientOK: '#16A34A',
    clientMissingInformation: '#7F1D1D',
    clientInvalidSoon: '#CA8A04',
    clientInvalid: '#E11D48',
  };

  public static cssClasses = {
    bookingArrivedInRoom: 'bg-green-300',
    bookingConfirmed: 'bg-blue-300',
    bookingDefault: 'bg-yellow-300',
    bookingCanceled: 'bg-red-300',
    bookingDeleted: 'bg-purple-300',
    bookingElse: 'bg-orange-300',
  };

  public static getClientTypeColor(clientType: string) {
    if (clientType.toLowerCase() === 'ts') {
      return CoreService.colors.clientTypeTS;
    } else if (clientType.toLowerCase() === 'domina') {
      return CoreService.colors.clientTypeDomina;
    } else {
      return CoreService.colors.clientTypeDefault;
    }
  }

  public static getUserNameLetters(client?: ClientDto | AppUserDto): string {
    if (client) {
      return client.prename?.charAt(0).toUpperCase()! + client.lastname?.charAt(0)?.toUpperCase()!;
    }
    return 'U';
  }

  public static getBookingCSS(booking: BookingDto): string {
    if (!booking.confirmed && booking.active === true) {
      return CoreService.cssClasses.bookingDefault;
    } else if (booking.confirmed && !booking.arrived && booking.active === true) {
      return CoreService.cssClasses.bookingConfirmed;
    } else if (booking.active === true) {
      return CoreService.cssClasses.bookingArrivedInRoom;
    } else if (booking.active === false && booking.status === 'CANCELED') {
      return CoreService.cssClasses.bookingCanceled;
    } else if (booking.active === false && booking.status === 'DELETED') {
      return CoreService.cssClasses.bookingDeleted;
    } else {
      return CoreService.cssClasses.bookingElse;
    }
  }

  public static whatsapp(c: ClientDto) {
    let number = '';
    if (c.contacts && c.contacts.phone && c.contacts.phone.length > 0) {
      const prefix = c.contacts.phone[0].prefix?.replace('+', '');
      number = `${prefix}${c.contacts.phone[0].number}`;
      number = 'https://wa.me/' + number;
    } else if (c.contacts && c.contacts.mobile && c.contacts.mobile.length > 0) {
      const prefix = c.contacts.mobile[0].prefix?.replace('+', '');
      number = `${prefix}${c.contacts.mobile[0].number}`;
      number = 'https://wa.me/' + number;
    }
    return number;
  }

  public static phoneCall(c: ClientDto) {
    let number = '';
    if (c.contacts && c.contacts.phone && c.contacts.phone.length > 0) {
      const prefix = c.contacts.phone[0].prefix?.replace('+', '');
      number = `${prefix}${c.contacts.phone[0].number}`;
      number = 'tel:' + number;
    } else if (c.contacts && c.contacts.phone && c.contacts.phone.length > 0) {
      const prefix = c.contacts.phone[0].prefix?.replace('+', '');
      number = `${prefix}${c.contacts.phone[0].number}`;
      number = 'tel:' + number;
    }
    return number;
  }

  public static customSort(item1: number, item2: number, sortOrder: any) {
    if (sortOrder === null) {
      return 0;
    }
    if (item1 && item2) {
      if (item1 > item2) {
        return -1;
      } else if (item1 < item2) {
        return 1;
      }
    }
    return 0;
  }

  public static customSortString(item1: string, item2: string, sortOrder: any) {
    if (sortOrder === null) {
      return 0;
    }
    if (item1 && item2) {
      if (item1 > item2) {
        return -1;
      } else if (item1 < item2) {
        return 1;
      }
    }
    return 0;
  }

  public static getDateString(period: Date | string | undefined, translationService: TranslationService): string {
    if (period !== undefined) {
      let d: Date = new Date();
      if (typeof period === 'string') {
        d = new Date(period);
      } else {
        d = new Date(period.valueOf());
      }
      // d.setHours(12);
      let monthStr = `${d.getUTCMonth() + 1}`;
      if (monthStr.length == 1) {
        monthStr = `0${monthStr}`;
      }
      let dayStr = `${d.getUTCDate()}`;
      if (dayStr.length == 1) {
        dayStr = `0${dayStr}`;
      }
      return `${translationService.getWeekDay(d.getUTCDay())} ${dayStr}.${monthStr}.${d.getUTCFullYear()}`;
    }
    return '';
  }
}
